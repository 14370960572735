body {
  background-color: $color-white;
  color: $color-brown;
}

.patio-purpura {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
  max-width: 1400px;
  min-width: 300px;
  padding: 0 10%;
}

.patio-purpura.patio-purpura--contact {
  .patio-purpura-content {
    &-wrapper {
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 0 20px;
      }
  
      .text {
        margin: 0;
      }
      .email {
        color: $color-brown;
        font-weight: bold;
        margin-top: 5px;
        text-decoration: none;

        @media screen and (min-width: 768px) {
          margin-left: 5px;
          margin-top: 0;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.patio-purpura-content {
  .title {
    color: $color-orange;
  }
}

