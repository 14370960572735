.patio-purpura-footer {
  align-items: center;
  border-top: solid 1px $color-brown;
  display: flex; 
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;

  @media screen and (min-width: 768px) {
    margin-top: 40px;
    padding: 40px 0;
  }

  &-logo {
    margin: 0 auto;
    width: 80px;
  }

  .email {
    color: $color-brown;
    text-decoration: none;
    margin-top: 20px;

    &:hover {
      text-decoration: underline;
    }
  }

  .patio-purpura-instagram {
    margin-top: 20px;
  }

  .copyright {
    font-size: 12px;
    margin: 0;
    text-align: center;
  }
}