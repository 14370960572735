.list {
  margin: 20px 0;

  .list-item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}