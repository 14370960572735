.patio-purpura-image {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  margin: 20px 0;
  width: 100%;

  img {
    height: auto;
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: 768px) {
      max-width: 700px;
    }
  }
}