.patio-purpura-header {
  border-bottom: solid 1px $color-brown;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  font-weight: 600;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 20px;

  @media screen and (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: left;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }

  &-logo {
    margin:  20px auto;
    width: 200px;

    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }

  .main-menu {    
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-left: 40px;
    }

    a {
      color: $color-brown;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}